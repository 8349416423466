
import {Vue, Component, Prop} from 'vue-property-decorator';
import AppTopBar from '@/components/UI/AppTopBar.vue';
import MenuTop from '@/components/MenuTop.vue';
import AppStore from '@/components/AppStore';

@Component({
  components: {MenuTop, AppTopBar}
})
export default class SettingsWrapper extends Vue {

  @Prop({required: true})
  title!: string;

  name = '';
  containerWidth = '1080px';

  created() {
    AppStore.onContainerWidth(v => this.containerWidth = v);
    AppStore.setDisplayModeTable();
  }


}

